import React from 'react';
import Lane from '../lib/Lane';
import Actions from '../lib/Actions';
import Display from '../lib/Display';
import Storage from "../lib/Storage";
import Endpoints from '../lib/Endpoints';
import Request from '../lib/Request';
import Reservation from '../lib/Reservation';
import Dispatcher from '../dispatchers/Dispatcher';
import Game from '../lib/Game';

import { createConsumer } from '@rails/actioncable';

export default class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            endTimestamp: null,
            prevEndTimestamp: null,
            endTime: null,
            warningTime: null,
            warned: false,
            expired: false,
        }
    }

    toggle = (action, value) => {
        Dispatcher.dispatch({
            type: action,
            data: value
        });   
    }

    /**
     * Check for cancellations by seeing if we have a current reservationNumber
     * and the response from the server is "not found"
     * @param {*} response response from booking app
     */
    checkForCancellation = (message='') => {
        if (this.props.app.reservationNumber && /^(no_reservation|no reservation)$/i.test(message)) {
            window.location.reload(false);
        }
    };

    /**
     * Update welcome message
     * @param {*} response response from booking app
     */
    checkWelcomeMessage = (welcomeMessage='') => {
        if (welcomeMessage !== this.props.welcomeMessage) {
            this.toggle(Actions.WELCOME_MESSAGE, welcomeMessage);
        }
    };

    /**
     * Check if the reservation has switched lanes.
     * If our lane is no longer in the list of lanes, reload the app
     * @param {*} response response from booking app
     * @param {*} laneNumber current lane number
     */
    checkLaneChange = (laneNumbers, laneNumber) => {
        if (
            !!laneNumbers
            && laneNumbers.length > 0
            && !laneNumbers.includes(laneNumber)
        ) {
            window.location.reload(false);
        }
    };

    checkIfTrainingStarted = (startedTraining) => {
        if (!this.props.startedTraining && startedTraining) {
            Lane.toggleStartedTraining(true);
        }
    };

    checkForTimeRemaining = (estimatedEnd) => {
        const second = 1000;
        const minute = 60 * second;

        this.setState({ endTimestamp: estimatedEnd })

        if (estimatedEnd !== null && estimatedEnd !== undefined) {
            // The ending time has changed so recalculate the new warning time
            // Reset the warned and expired flags since the time could have been extended
            if (this.state.endTimestamp !== this.state.prevEndTimestamp) {
                const endTime = new Date(estimatedEnd)

                this.setState({
                    prevEndTimestamp: estimatedEnd,
                    endTimestamp: estimatedEnd,
                    endTime: endTime,
                    warningTime: new Date( endTime.getTime() - (15 * minute) ),
                    warned: false,
                    expired: false,
                })

                this.toggle(Actions.MODAL_TIME_WARNING, false);
                this.toggle(Actions.SHOWN_TIME_WARNING, false);
                this.toggle(Actions.RESERVATION_TIMEOUT, false);
                this.toggle(Actions.END_TIME, endTime);
                Lane.agreedTimeWarning(null);
                Lane.extendReservation(null);

                setTimeout( () => {
                    Game.sendGameState(this.props.app, this.props.game);
                }, 1500);
            }

            if (this.props.app.groupInLane && !!this.state.warningTime) {
                if (new Date() > this.state.warningTime && !this.state.warned) {
                    this.toggle(Actions.MODAL_TIME_WARNING, true);
                    this.toggle(Actions.SHOWN_TIME_WARNING, true);
                    this.setState({ warned: true })
                    setTimeout( () => {
                        Game.sendGameState(this.props.app, this.props.game);
                    }, 1500);
                }

                if (new Date() >= this.state.endTime && !this.state.expired) {
                    // console.log("Expired: ", expired);
                    this.toggle(Actions.RESERVATION_TIMEOUT, true);
                    this.setState({ expired: true })
                    setTimeout( () => {
                        Game.sendGameState(this.props.app, this.props.game);
                    }, 1500);
                }
            }
        }
    }

    // NOTE:
    // this has been converted to a broadcast push instead of a timer based fetch
    // and this loop is now a fallback method of fetching reservation information
    // if the websocket ever disconnects, even momentarily.
    //
    reservationStatusLoop = () => {
        if (!!window.reservationStatusLoop) { return; }

        let laneNumber = null;

        window.reservationStatusLoop = window.setInterval(() => {
            laneNumber = parseInt(Storage.getLaneNumber());

            // this will probably never be used because the initial
            // reservation load takes place in AppContainer's initialFetch code
            // but it's left in as a good fallback to ensure we get the reso
            if (!this.props.app.reservationNumber) {
                Reservation.load(this.props.app.locationId, this.props.app.laneNumber);
            } else {
                Request.get(Endpoints.reservationStatus(this.props.app.reservationNumber)).then((response) => {
                    this.checkWelcomeMessage(response.data?.data?.welcomeMessage);
                    this.checkLaneChange(response.data?.data?.laneNumbers, laneNumber);
                    this.checkIfTrainingStarted(response.data?.data?.startedTraining);
                    this.checkForTimeRemaining(response.data?.data?.estimatedEnd);
                }).catch(err => {
                    // it would be better if this didn't 404 so we can check the 
                    // response in a cleaner way.
                    this.checkForCancellation(err.response?.data?.message);
                });
            }
        }, 15000);
    }

    // NOTE no longer used
    //
    //sendGameStateLoop = () => {
    //    window.gameStateLoop = window.setInterval(() => {
    //        // this is a workaround to only start sending gamestate up when the group has been marked in lane.
    //        // it will let the TV app correctly poll (IF the websocket is disconnected) for if the reservation
    //        // has ended before the next one starts sending up data.
    //        if (this.props.app.groupInLane) {
    //            Request.post(Endpoints.sendGameState(this.props.app.reservationNumber, this.props.app.laneNumber), {
    //                json_block: { 
    //                                "game": this.props.game.game, "readyToPlay": this.props.game.readyToPlay, "gameState": this.props.game.gameState,
    //                                "scoringStyle": this.props.game.scoringStyle, "currentThrower":  this.props.game.currentThrower,
    //                                "currentTeam": this.props.game.currentTeam, "bucketListBlueBalls": this.props.game.bucketListBlueBalls, 
    //                                "bustOrStay": this.props.game.bustOrStay, "magicNumberBlueBalls": this.props.game.magicNumberBlueBalls,
    //                                "shownTimeWarning": this.props.app.shownTimeWarning, "agreedTimeWarning": this.props.app.agreedTimeWarning,
    //                                "reservationTimeout": this.props.app.reservationTimeout, "reservationExtended": this.props.app.reservationExtended,
    //                                "modalScoreMessage": this.props.game.modalScoreMessage,
    //                                "throwData": { "gameState": this.props.game.eventGameState, "currentThrower": this.props.game.eventCurrentThrower, "currentTeam": this.props.game.eventCurrentTeam, "points": this.props.game.eventPoints, "timestamp": this.props.game.eventTimestamp }
    //                            }
    //            });
    //        }
    //    }, 1500);
    //}

    connectToResourceChannel = () => {
        createConsumer(Endpoints.CABLE_URL).subscriptions.create({
            channel: 'ResourceChannel',
            location_id: this.props.app.locationId,
            lane_number: this.props.app.laneNumber,
        }, {
            connected: () => {
                console.log('ResourceChannel: CONNECTED')

                // stop the fallback polling loop
                if (window.reservationStatusLoop) {
                    window.clearInterval(window.reservationStatusLoop)
                }
            },
            disconnected: () => {
                console.log('ResourceChannel: DISCONNECTED')

                // fallback to the polling loop
                this.reservationStatusLoop()
            },
            received: ({ data, type }) => {
                console.log('ResourceChannel: RECEIVED', type, data)

                switch(true) {
                    case type === 'load_or_update_reservation' :
                        const laneNumber = parseInt(Storage.getLaneNumber())

                        if (this.props.app.reservationNumber) {
                            this.checkWelcomeMessage(data?.welcomeMessage)
                            this.checkLaneChange(data?.laneNumbers, laneNumber)
                            this.checkIfTrainingStarted(data?.startedTraining);
                            this.checkForTimeRemaining(data?.estimatedEnd)
                        } else {
                            Dispatcher.dispatch({ type: Actions.LOAD_RESERVATION, data: data })
                        }
                        break

                    case type === 'no_reservation' :
                        if (this.props.game.gameState?.length > 0 && !!this.props.app.reservationEstimatedEndTime) {
                            this.checkForTimeRemaining(this.props.app.reservationEstimatedEndTime)
                        } else {
                            this.checkForCancellation(type)
                        }
                        break

                    default :
                        console.log('UNKNOWN BROADCAST TYPE!', type, data)
                }
            }
        })
    }

    componentDidMount() {
        this.connectToResourceChannel();
        // this.endTimeLoop();
        // this.sendGameStateLoop();
    }

    formatReservationName = () => {
        if (this.props.app.welcomeMessage) {
            return this.props.app.welcomeMessage;
        } else {
            let lastName = this.props.app.reservationName.split(' ').pop();
            return "Welcome " + lastName + " Party!";
        }
    }

    showHome = () => {
        if (this.props.display.showBrowseGames || this.props.app.groupInLane) {
            return false;
        } else {
            return true
        }
    }

    render() {
        return (
            <div id="home" className={`${this.showHome() ? "" : "inactive"}`}>
                <div id="lane">{this.props.app.reservationName ? this.formatReservationName() : this.props.app.laneName}</div>
                <div id="browse-games-btn" className="btn btn-gold" 
                    onClick={() => {
                        Lane.getGameRules();
                        Display.toggleBrowseGames(true);
                    }}>
                    Browse Games
                </div>
            </div>
        )
    }

}
